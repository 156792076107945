
* {
    box-sizing: border-box;
}

.footer__container {
    color: #ffffff;
    /* background-color: #242424; */
    background-color: rgb(90, 90, 90);
    padding: 1rem 0 1rem 0;
    width: 100%;
}

.footer__wrapper {
    padding: 1rem 1rem;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.footer__box {
    text-align: center;
}

.header__text {
    margin-top: 10px;
}

.para__text {
    margin: 10px 0px;
}

a {
    color: #ffffff;
}

.header > h1 {
    font-size: 2rem;
}

/* Social Links CSS */

.social-icon-link{
    color: #fff;
    font-size: 24px;
    padding: 0px 20px;
}

.social-media{
    max-width: 100%;
    width:100%
}

.social-media-wrap{
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 10px auto 0 auto;
}

.website-rights{
    color: #fff;
    text-align: center;
}

.social-icons{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
}

.social-logo{
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
}

.social-logo img{
    width: 100px;
    max-width: 100%;
    max-height: 100%;
  }

@media only screen and (min-width: 600px) and (max-width: 1024px){

    .footer__wrapper {
        padding: 1rem 1rem;
    }

}

@media screen and (max-width: 600px){

    .footer__wrapper{
        flex-direction: column;
    }

    .footer__box{
        height: fit-content;
        margin-bottom: 30px;
    }

    /* .footer__box__header {
        padding-bottom: 5px;
    } */

    .header__text{
        margin-top: 5px;
        font-size: 1.3rem;
    }


    .footer-links{
        padding-top: 2rem;
    }

    .footer-input{
        width: 100%;
    }

    .btn{
        width: 100%;
    }

    .footer-link-wrapper{
        flex-direction: column;
    }
    
    .footer-link-items{
        flex-direction: column;
    }

    .social-logo{
        font-size: 1.5rem;
    }
}
@media screen and (max-width: 768px){

    .social-media-wrap{
        flex-direction: column;
        margin-top: 0px;
    }

    .social-logo, .website-rights, .social-icons{
        margin: 5px;
    }

    .social-icons{
        margin-bottom: 0px;
    }
}