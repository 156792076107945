video{
    box-sizing: border-box;
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    /* opacity: 0.7; */
}

.hero-container{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}

.hero-container h1{
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
}

.hero-container p{
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.hero-btns{
    margin-top: 32px;
}

.hero-btns .btn{
    margin: 6px;
}

.fa-clipboard-list{
    margin-left: 4px;
}

@media screen and (max-width: 960px){
    .hero-container > h1{
        font-size: 70px;
        margin-top: -150px;
    }
}

@media screen and (max-width: 768px){
    .hero-container > h1{
        font-size: 50px;
        margin-top: -100px;
    }

    .hero-container > p {
        font-size: 30px;
    }

    .btn-mobile{
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }
}

@media screen and (max-width: 768px){
    .hero-container > h1{
        font-size: 30px;
    }
    .hero-container > p {
        width: 75%;
        font-size: 20px;
    }
}