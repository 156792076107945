/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */

/* jpg image of menu */
.about__wrapper {
    display: flex;
    flex-direction: row;
    box-shadow: inset 0 0 0 1000px rgb(0,0,0,0.09);
    width: 100%;
    height: 100%;
    color: #242424;
    justify-content: space-around;
    margin: 0px auto;
    padding: 20px;
}

.about__bgimage {
    display: flex;
    flex-basis: 27%;
    padding: 10px;
    justify-content: center;
    align-items: center;
}

.about__content {
    display: flex;
    flex-direction: column;
    padding: 10px;
    flex-basis: 70%;
}

.about-img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
}

.about__heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: 0px auto;
    height: 15vh;
    font-family: 'Open Sans', sans-serif;
}

.who-we-are {
    display: flex;
    flex-direction: column;
    /* height: 70%; */
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 10px;
}

.firstpara, .secondpara {
    font-family: 'Open Sans';
    font-weight: lighter;
    font-size: 1.1rem;
    letter-spacing: 1px;
    margin-bottom: 20px;
}



/* For regular table type menu */
/* .menu {
    width: 50%;
    margin: 0px auto;
    height: 15vh;
    font-family: 'Open Sans', sans-serif;
}

table, th, td {
      border: 1px solid black;
      border-collapse: collapse;
}

th, td {
    padding: 15px;
    font-family: 'Handlee', cursive;
    font-family: 'Open Sans', sans-serif;
}

td:hover{
    background-color: #242424;
    color: white;
}

th {
    text-align: left;
    font-size: 1.4rem;
}

td {
    text-align: left;
    font-size: 1.3rem;
}

table {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 75%;
    margin: 0px auto;
    border-spacing: 5px;
    border-radius: 30px;
}
thead {
    height: 650px;
    margin: 10px auto;   
    font-size: 1rem;
}

thead > h2 {
    width: 100%;
    margin: 5px auto 15px auto;
    text-align: center;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
} */





