html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.menu,
.recipes-blog,
.contact-us {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

/* .menu {
  background-image: url('./images/BSK-Flyer.jpg');
  height: 1535px;
  background-position: bottom;
  background-size: unset;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
} */

.recipes-blog {
  background-image: url('./images/eggs.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contact-us {
  background-image: url('./images/contact-us.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}