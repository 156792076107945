

.contactus-container{
    height: 200%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.1);
}

.background-image{
    width: 30%;
    position: fixed;
    top: 60%;
    left: 0px;
    z-index: -10;
    transform: scaleX(-1);
    opacity: 0.7;
}

.user_form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 20px;
    height: 150%;
    width: 50%;
    margin: 10px 20px 10px auto;
    letter-spacing: 1px;
    color: #242424;
}

.user-inputs p{
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100vh;
    font-size: 22px;
    transform: width 2s ease;
}

.user-inputs input{
    margin-bottom: 20px;
    width: 70vh;
    height: 40px;
    font-size: 16px;
    letter-spacing: 1px;
    transform: width 2s ease;

}

.user-inputs textarea{
    width: 70vh;
    font-size: 20px;
    transform: width 2s ease;

}

.user-inputs .btn--outline{
    margin: 20px 0px;
    padding: 8px 40px;
    font-size: 30px;
}

.user-inputs .btn--outline:hover{
    background-color: #242424;
    color: #fff;
    transform: scale(1.1);
}

@media screen and (max-width: 820px){
    .user-inputs p, input, textarea{
        font-size: 18px;
        width: 50vh;
    }

    .user-inputs input{
        width: 40vh;
    }

    .user-inputs textarea{
        width: 40vh;
    }

    .user-inputs .btn--outline{
        font-size: 22px;
    }
}