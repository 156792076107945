/* img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 80px;
    left: 0px;
    z-index: -1;
} */

.recipesblog-container{
    height: 400%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main__header{
    margin-top: 20px;
}

.main__header > a{
    color: black
}

/* copied */

.menu__item__link {
    border-radius: 50%;
}