
.items {
    /* padding: 1rem 0 0 0; */
    background: #fff;
    box-shadow: inset 0 0 0 1000px rgb(0,0,0,0.09);
    border-radius: 10px;

}
    
.menu__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 100%;
    margin: 20px auto;
}
    
.menu__wrapper_four {
    position: relative;
    margin: 35px 0 35px 0;
}

.menu__wrapper_three {
    position: relative;
    margin: 35px 0 35px 0;
}

.menu__wrapper_two {
    position: relative;
    margin: 35px 0 35px 0;
}
    
.menu__items {
    display: flex;
    margin-bottom: 24px;
    list-style-type: none;
}

.last {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    /* height: 60%; */
    position: relative;
    left: 37.5%;
}
    
.menu__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
}
  
.menu__items > div {
    margin: 0 1rem 0 1rem;
}
  
.menu__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    /* border-radius: 10px; */
    overflow: hidden;
    text-decoration: none;
}
    
.menu__item__pic-wrap {
    position: relative;
    width: 100%;
    /* padding-top: 67%;  before change*/
    padding-top: 90%;
    overflow: hidden;
}
    
.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}
    
.menu__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 16px;
    color: #fff;
    background-color: #242424;
    box-sizing: border-box;
    border-radius: 5px 5px 0 0;
}
    
.menu__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}
    
.menu__item__img:hover {
    transform: scale(1.1);
}
    
.menu__item__info {
    width: 250px;
    height: 125px;
    overflow: hidden;
    padding: 5px 50px 5px 50px;
}

.item__description {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-between;
}

.menu__item__price {
    /* color: rgb(27, 125, 218); */
    color: #242424;
}
    
.menu__item__text {
    margin-top: 10px;
    color: #242424;
    font-size: 14px;
    line-height: 18px;
}
    
@media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }

    .menu__wrapper_three {
        display: none;
    }

    .menu__wrapper_two {
        display: none;
    }
    
}

@media only screen and (min-width: 912px) and (max-width: 1200px) {
    
    .menu__wrapper_four {
        display: none;
    }

    .menu__wrapper_two {
        display: none;
    }
    
}

@media only screen and (min-width: 600px) and (max-width: 911px) {

    .menu__wrapper_four {
        display: none;
    }

    .menu__wrapper_three {
        display: none;
    }
    
}
    
@media only screen and (max-width: 600px) {

    .menu__wrapper_four {
        display: none;
    }

    .menu__wrapper_three {
        display: none;
    }

    .menu__items {
        flex-direction: column;
    }
    
    .menu_item {
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .menu__item__link{
        /* width: 50%; */
        margin-bottom: 20px;
    }
    .last {
        width: 50%;
        margin: 0px auto;
        left: 0px;
    }
}


